<template>
  <v-expansion-panel
    v-if="(meetings && meetings.length > 0) || voteResults"
    class=""
  >
    <v-expansion-panel-header>
      <span>{{ $t('detail.documentData')}}</span>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div
        v-if="meetings && meetings.length > 0"
      >
        <span class="text-body-2 font-weight-bold text--secondary"><v-icon color="grey darken-1">mdi-calendar</v-icon> {{ $t('agenda.onTheAgenda') }}</span>
      </div>
      <div
        v-if="meetings && meetings.length > 0"
        class="text-body-2 mb-1"
      >
        <ul class="text-body-2 plain-li text--secondary">
          <li
            v-for="(item, index) in meetings"
            :key="index"
            class="mb-2"
            :class="{ 'mt-2': index === 0 }"
          >
            <strong>{{ item.meeting ? 'Vergadering: ' : 'Agendapunt: ' }}</strong>
            <a
              :href="item.url"
              target="_blank"
            >
              {{ item.agendaItem ? item.title : item.subject }}&nbsp;&#187;
            </a>
          </li>
        </ul>
      </div>
      <div
        v-if="voteResults?.overallResult"
        class="text-body-2 font-weight-bold text--secondary mt-6"
      >
        <p> <!-- Very similar to the one in BasicDetails.vue -->
          <v-icon color="grey darken-1">mdi-gavel</v-icon>
          <span class="text-body-2 font-weight-bold text--secondary ml-1">Status:</span>
          <span :style="voteResultStatusStyling(voteResults.overallResult)">
            {{ voteResults.overallResult }}
          </span>
        </p>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { voteResultStatusStyling } from '@/helpers/voteResultsHelper';

export default {

  props: {
    meetings: {
      type: Array,
      required: false,
    },
    voteResults: {
      type: String,
      required: false,
    },
  },

  filters: {

  },

  computed: {

  },

  methods: {
    voteResultStatusStyling,
  },

  mounted() {

  },

};
</script>

<style lang="scss" scoped>
  a {
    text-decoration: none;
  }
</style>
