<template>
  <div class="pre-fixer">
    <v-card
      v-if="docHasAudio"
      class="audio-wrapper"
      elevation="6"
    >
      <div
        v-show="audioLoaded"
        style="width: 100%"
      >
        <audio
          id="audio-to-text"
          class="audioPlayer"
          ref="audioPlayer"
          controls
          @timeupdate="scrollToCurrentTextElem"
          @play="scrollToCurrentTextElem"
          @loadeddata="() => audioLoaded = true"
          @error="() => audioError = true"
          crossorigin="use-credentials"
        ></audio>
        <v-btn
          class="followAudioButton mt-n2"
          @click="onFollowToggle"
          small
        >
          <v-icon small>
            {{ followAudio ? 'mdi-close' : 'mdi-chevron-double-down' }}
          </v-icon>
          {{
            followAudio ? 'Stop automatisch scrollen' : 'Automatisch scrollen'
          }}
        </v-btn>
      </div>
      <span
        v-if="audioError"
        class="text--secondary ma-2"
      >
        {{ $t('debates.debateAudioError')}}
      </span>
      <div
        v-else-if="!audioLoaded"
        class="ma-4"
      >
        <v-progress-circular
          indeterminate
          class="mr-1 mb-1"
        ></v-progress-circular>
        {{ $t('debates.debateAudioLoading') }}
      </div>
    </v-card>
    <v-expansion-panels v-model="panel" multiple>
      <basic-details
        v-if="shouldShowBasicDetails"
        :meetings="meetings"
        :cases="document.related?.cases"
        :relatedAttachments="document.related?.documents || []"
        :sourceDocuments="document.related?.sourceDocuments || []"
        :id="documentId"
        :documentNumbers="documentNumbers"
        :persons="document.persons"
        :locationName="document.locationName"
        :debateTime="getDebateTime"
      />

      <list-subjects
        v-if="document && document.subjects.length > 0"
        :subjects="document.subjects"
      />

      <list-references :refs="refs" :id="documentId" />
    </v-expansion-panels>
  </div>
</template>

<script>
import fileApi from '@/api/document/document-api-file';
import ListSubjects from './ListSubjects.vue';
import BasicDetails from './BasicDetails.vue';
import ListReferences from './ListReferences.vue';

export default {
  name: 'nl-detail-sidebar',

  components: {
    BasicDetails,
    ListSubjects,
    ListReferences,
  },

  props: {
    document: {
      type: Object,
      required: true,
    },
    documentId: {
      type: String,
      required: true,
    },
    docHasAudio: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      audioPlayer: null,
      audioLoaded: false,
      audioError: false,
      panel: [0, 1, 2, 3],
      followAudio: true,
      ignoreScrollEvent: false,
      firstPlay: true,
    };
  },

  mounted() {
    // Disable following the text as soon as the user tries to intentionally scroll anywhere
    window.addEventListener('scroll', () => {
      if (!this.ignoreScrollEvent) this.followAudio = false;
      this.ignoreScrollEvent = false;
    });

    if (this.docHasAudio) {
      this.audioPlayer = this.$refs.audioPlayer;
      this.audioPlayer.src = fileApi.getFileStreamUrl(this.document.type, this.documentId, { mimeType: 'audio/mpeg', filename: this.documentId });
    }
  },

  methods: {
    onFollowToggle() {
      this.followAudio = !this.followAudio;
      if (this.followAudio) this.scrollToCurrentTextElem();
    },

    scrollToCurrentTextElem() {
      const { textChunks } = this.document;

      if (!this.audioPlayer) return;
      const currentTimestamp = this.audioPlayer.currentTime;

      textChunks.find((chunk) => {
        if (
          chunk.timestamps[0].$numberDecimal <= currentTimestamp
          && chunk.timestamps[1].$numberDecimal > currentTimestamp
        ) {
          const element = document.getElementById(chunk._id);
          // Update which lines are past:
          Array.from(document.querySelectorAll('.audioText > .audioTextFragment > .pastAudioLine'))
            .forEach((elem) => elem.classList.remove('pastAudioLine'));
          element.parentNode.classList.add('marker');
          // ".audioTextFragment:has(~ .marker)" Selects any audioTextFragment *before* the marker elem.
          Array.from(document.querySelectorAll('.audioText > .audioTextFragment:has(~ .marker) > .text-fragment'))
            .forEach((elem) => elem.classList.add('pastAudioLine'));
          element.parentNode.classList.remove('marker');

          this.scrollToTextElem(element);
          return true;
        }
        return false;
      });
    },

    scrollToTextElem(element, ignorePreviousPast) {
      if (element) {
        const position = element.offsetTop - 20;
        if (this.prevElement) {
          this.prevElement.classList.remove('currentAudioLine');
          if (!ignorePreviousPast) this.prevElement.classList.add('pastAudioLine');
        }
        element.classList.add('currentAudioLine');
        if (this.followAudio) {
          this.ignoreScrollEvent = true;
          window.scrollTo({
            top: position,
            behavior: 'smooth',
          });
        }
        this.prevElement = element;
      }
    },
  },

  computed: {
    meetings() {
      const agendas = [];
      const { meetings, agendaItems } = this.document.related || {};

      if (meetings?.length > 0) {
        meetings.forEach((item) => {
          item.meeting = true;
          agendas.push(item);
        });
      }

      if (agendaItems?.length > 0) {
        agendaItems.forEach((item) => {
          item.agendaItem = true;
          agendas.push(item);
        });
      }

      return agendas;
    },

    refs() {
      // EUROPARL refs
      const europarl = [];
      if (this.document && this.document.euReferenceCodes) {
        europarl.push(...this.document.euReferenceCodes);
      }

      // DLRC ref(s)
      const dhapi = [];
      if (this.document.related && this.document.related.dossier) {
        const { dossierId } = this.document.related.dossier;
        if (dossierId) dhapi.push(dossierId);
      }

      // For consistency reasons euRefs should be an object as we do also in other places.
      let result = {};
      if (europarl.length > 0) result = { europarl };
      if (dhapi.length > 0) result = { ...result, dhapi };
      return result;
    },

    documentNumbers() {
      return {
        dossier: this.document.related?.dossier?.dossierId,
        entityCode: this.document.entityCode,
      };
    },

    getDebateTime() {
      return {
        start: this.document.startedAt,
        end: this.document.endedAt,
      };
    },

    shouldShowBasicDetails() {
      const cases = this.document?.related?.cases;
      const relatedAttachments = this.document?.related?.documents;
      const sourceDocuments = this.document?.related?.sourceDocuments;
      const { meetings, documentNumbers } = this;

      return !!(
        cases?.length
        || relatedAttachments?.length
        || sourceDocuments?.length
        || meetings?.length
        || documentNumbers?.dossier
        || documentNumbers?.entityCode
        || this.document?.persons
        || this.document?.locationName
      );
    },
  },
};
</script>

<style lang="scss" scoped>
div.v-expansion-panel[aria-expanded='true'] {
  margin-bottom: -12px;
}

.pre-fixer {
  display: inline; // Little hack that needs to be here for the "position: sticky" below to apply to the whole page.
}

.audio-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: sticky;
  top: 5em;
  z-index: 8;

  margin-bottom: 1em;

  audio {
    width: 100%;
    opacity: 1;

    // Remove rounded corneres on chrome
    // TODO We probably need a completely custom audio player UI rather than the browsers default.
    &::-webkit-media-controls-enclosure {
      border-radius: 0;
    }
    &::-webkit-media-controls-panel {
      background-color: #009688;
    }
    &::-webkit-media-controls-current-time-display {
      color: #fff;
    }
    &::-webkit-media-controls-time-remaining-display {
      color: #fff;
    }
  }

  .followAudioButton {
    width: 100%;
  }
}
</style>
