<template>
  <feature-teaser-dialog :title="$t('modules.EUDashboard')">
    <p>{{ $t('modules.moduleAccessWarningEU') }}</p>
  </feature-teaser-dialog>
</template>

<script>
import FeatureTeaserDialog from '@/components/FeatureTeaserDialog.vue';

export default {
  components: {
    FeatureTeaserDialog,
  },
};
</script>
