<template>
  <div>
    <div v-if="locationName || time">
      <ul class="mb-2">
        <li v-if="locationName" class="text-body-2 text--secondary">
          <strong>{{$t('generic.location')}}: </strong> {{ locationName }}
        </li>
        <li v-if="time" class="text-body-2 text--secondary">
          <strong>{{$t('generic.time')}}: </strong>{{ formattedStartTime }} - {{ formattedEndTime }}
        </li>
      </ul>
    </div>
    <div v-if="formattedPersons.size">
      <div class="mt-4">
        <span class="text-body-2 font-weight-bold text--secondary">
          <v-icon color="grey darken-1" size="1.2rem">mdi-bank</v-icon> {{ $t('debates.inTheRoom') }}
        </span>
      </div>
      <div class="text-body-2 mt-4 mb-4">
        <div
          v-for="([personFunction, persons], index) in Array.from(
            formattedPersons
          )"
          :key="`function-${index}`"
        >
          <span class="text-body-2 text--secondary">
            {{ personFunction }}
          </span>
          <ul class="text-body-2 plain-li text--secondary">
            <li v-for="person in persons" :key="person.actorId" class="mb-2 mt-2">
              <strong>{{ person.name }}</strong>
              <span v-if="person.showFunction">
                ({{ person.function }})
              </span>
              <span v-if="person.partyShorthand">
                ({{ person.partyShorthand }})
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'DebateDetails',

  props: {
    persons: {
      type: Array,
      required: false,
    },
    locationName: {
      type: String,
      required: false,
    },
    time: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      formattedStartTime: 'N.v.t',
      formattedEndTime: 'N.v.t',
    };
  },

  mounted() {
    if (this.time && this.time.start && this.time.end) {
      this.formattedStartTime = moment(this.time.start).format('HH:mm');
      this.formattedEndTime = moment(this.time.end).format('HH:mm');
    }
  },

  computed: {
    formattedPersons() {
      const uniquePersons = [...this.persons].reduce((personsByRole, person) => {
        if (!personsByRole.find((p) => p.actorId === person.actorId)) {
          personsByRole.push(person);
        }
        return personsByRole;
      }, []);

      const sortedPersons = uniquePersons.sort((a, b) => {
        if (a.partyShorthand === b.partyShorthand) return 0;
        return a.partyShorthand < b.partyShorthand ? 1 : -1;
      });

      // Use a Map for debateRoles to preserve insertion order and for key-value association
      const initialRolesMap = sortedPersons.reduce((debateRolesMap, person) => {
        const personCopy = { ...person };
        personCopy.roles.forEach((role) => {
          let roleCopy = role;
          if (!debateRolesMap.has(roleCopy)) {
            debateRolesMap.set(roleCopy, []);
          }

          // sometimes there are external people attending the debates, and we want to cover that case as well
          // the external persons have Kabinetsleden assigned to them which is not correct, so we change it to Deelnemers
          if (personCopy.function === '' && personCopy.roles.includes('Kabinetsleden')) {
            personCopy.roles = ['Deelnemers'];
            roleCopy = 'Deelnemers';
            if (!debateRolesMap.has('Deelnemers')) {
              debateRolesMap.set('Deelnemers', []);
            }
          }

          // Add function to name if person is a member of the cabinet
          if (personCopy.roles.includes('Kabinetsleden')) {
            personCopy.showFunction = true;
          }

          const rolesArray = debateRolesMap.get(roleCopy);
          rolesArray.push(personCopy);
          debateRolesMap.set(roleCopy, rolesArray);
        });
        return debateRolesMap;
      }, new Map());

      if (!initialRolesMap.get('Kabinetsleden')?.length) {
        initialRolesMap.delete('Kabinetsleden');
      }
      // Extract the roles 'Voorzitter' and 'Sprekers' if they exist
      const voorzitter = initialRolesMap.get('Voorzitter') || [];
      const sprekers = initialRolesMap.get('Sprekers') || [];

      // Remove these roles to avoid duplication in the final map
      initialRolesMap.delete('Voorzitter');
      initialRolesMap.delete('Sprekers');

      // Create a new map with 'Voorzitter' at the start if it exists
      const formattedMap = new Map();

      if (voorzitter.length > 0) {
        formattedMap.set('Voorzitter', voorzitter);
      }

      initialRolesMap.forEach((value, key) => {
        formattedMap.set(key, value);
      });

      // Add 'Sprekers' last if it existed
      if (sprekers.length > 0) {
        formattedMap.set('Sprekers', sprekers);
      }

      return formattedMap;
    },
  },
};
</script>
