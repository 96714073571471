<template>
  <v-expansion-panel
    v-if="meetings?.length
      || cases
      || relatedAttachments?.length
      || sourceDocuments?.length
      || persons?.length
      || locationName"
  >
    <v-expansion-panel-header>
      <span>{{ $t('detail.documentData')}}</span>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div
        v-if="documentNumbers.dossier || documentNumbers.entityCode"
        class="text-body-2 text--secondary mb-5"
      >
        <ul>
          <li>
            <span
            >
              <span v-if="documentNumbers.dossier">
                {{$t('detail.file')}}: <span class="text-body-2 font-weight-bold text--secondary">{{ documentNumbers.dossier }}&nbsp;</span>
              </span>
              {{ $t('detail.number')}}: <span class="text-body-2 font-weight-bold text--secondary">{{ documentNumbers.entityCode }}</span>
            </span>
          </li>
        </ul>
      </div>
      <div
        v-if="currentMeetings.length > 0 || pastMeetings.length > 0"
        :class="`${currentMeetings.length === 0 ? 'mb-2' : ''}`"
      >
        <span class="text-body-2 font-weight-bold text--secondary"><v-icon color="grey darken-1">mdi-calendar</v-icon> {{ $t('agenda.onTheAgenda') }}</span>
      </div>
      <div>
        <div
          v-if="currentMeetings.length > 0"
          class="text-body-2 mb-1"
        >
          <ul class="text-body-2 plain-li text--secondary">
            <li
              v-for="(item, index) in displayedList('currentMeetings')"
              :key="index"
              class="mb-2"
              :class="{ 'mt-2': index === 0 }"
            >
              <span v-if="item.meeting && item.subject">
                <a
                  :href="item.url"
                  target="_blank"
                >
                  <strong>
                    {{ item.date ? `${item.date} ` : '' }}
                  </strong>
                  {{ item.startTime ? `(${item.startTime}${item.endTime ? ` - ${item.endTime}` : ''}` : '' }})
                  {{ item.activityType ? `${item.activityType}` : 'Vergadering' }}
                  {{ item.subject !== item.activityType ? ` - ${item.subject}` : '' }}{{ item.documentType ? `(${item.documentType})` : '' }}&nbsp;&#187;
                </a>
              </span>
              <span v-if="item.agendaItem && item.title">
                <a
                  :href="item.url"
                  target="_blank"
                >
                  <strong>
                    {{ item.date ? `${item.date} - ` : '' }}
                  </strong>
                  {{ item.docType ? `${item.docType} - ` : `${this.$t('detail.agendaPoint')} - ` }}
                  {{this.title}}{{ item.documentType ? `(${item.documentType})` : '' }}&nbsp;&#187;
                </a>
              </span>
            </li>
          </ul>
          <show-more-button
            :show="showAllItems.currentMeetings"
            @update:show="value => showAllItems.currentMeetings = value"
            v-if="currentMeetings.length > this.foldedItemsLimit"
          />
        </div>
      </div>
      <div>
        <div
          class="text-body-2 mb-2"
          v-if="pastMeetings.length > 0"
        >
          <span class="text-body-2 text--secondary">{{ $t('detail.concluded')}}</span>
          <ul class="text-body-2 plain-li past-list text--secondary">
            <li
              v-for="(item, index) in displayedList('pastMeetings')"
              :key="index"
              class="check mb-1"
            >
              <span v-if="item.meeting && item.subject">
                <a
                  :href="item.url"
                  target="_blank"
                  class="text-body-2 text--secondary"
                >
                  <strong>
                    {{ item.date ? `${item.date} ` : '' }}
                  </strong>
                  {{ item.startTime ? `(${item.startTime}${item.endTime ? ` - ${item.endTime}` : ''}` : '' }})
                  {{ item.activityType ? `${item.activityType}` : $t('detail.meeting') }}
                  {{ item.subject !== item.activityType ? ` - ${item.subject}` : '' }}{{ item.documentType ? `(${item.documentType})` : '' }}&nbsp;&#187;
                </a>
              </span>
              <span v-if="item.agendaItem && item.title">
                <a
                  :href="item.url"
                  target="_blank"
                >
                  <strong>
                    {{ item.date ? `${item.date} - ` : '' }}
                  </strong>
                  {{ item.docType ? `${item.docType} - ` : '${detail.agendaPoint} - ' }}
                  {{this.title}}{{ item.documentType ? `(${item.documentType})` : '' }}&nbsp;&#187;
                </a>
              </span>
            </li>
          </ul>
          <show-more-button
            :show="showAllItems.pastMeetings"
            @update:show="value => showAllItems.pastMeetings = value"
            v-if="pastMeetings.length > this.foldedItemsLimit"
          />
        </div>
      </div>

      <div
        v-if="sourceDocuments?.length"
        class="text-body-2 mt-4 mb-4"
      >
        <span class="text-body-2 font-weight-bold text--secondary"><v-icon color="grey darken-1">mdi-attachment</v-icon> {{ $t('detail.parliamentaryDocument')}}</span>
        <ul class="text-body-2 plain-li text--secondary">
          <li
            v-for="(item, index) in displayedList('sourceDocuments')"
            :key="index"
            class="mb-1"
          >
            <a
              :href="item.url"
              target="_blank"
            >
              <strong>
                {{ item.date ? `${item.date} - ` : '' }}
              </strong>
              {{ item.title }} {{ item.dossierId ? `(${item.dossierId})` : item.documentId ? `(${item.documentId})` : '' }}&nbsp;&#187;
            </a>
          </li>
        </ul>
        <show-more-button
          :show="showAllItems.sourceDocuments"
          @update:show="value => showAllItems.sourceDocuments = value"
          v-if="sourceDocuments.length > this.foldedItemsLimit"
        />
      </div>

      <div
        v-if="relatedAttachments?.length"
        class="text-body-2 mt-4 mb-4"
      >
        <span class="text-body-2 font-weight-bold text--secondary"><v-icon color="grey darken-1">mdi-attachment</v-icon> {{ $t('detail.attachment') }}</span>
        <ul class="text-body-2 plain-li text--secondary">
          <li
            v-for="(item, index) in displayedList('relatedAttachments')"
            :key="index"
            class="mb-1"
          >
            <a
              :href="item.url"
              target="_blank"
            >
              <strong>
                {{ item.date ? `${item.date} - ` : '' }}
              </strong>
              {{ item.title }} {{ item.documentId ? `(${item.documentId})` : '' }}&nbsp;&#187;
            </a>
          </li>
        </ul>
        <show-more-button
          :show="showAllItems.relatedAttachments"
          @update:show="value => showAllItems.relatedAttachments = value"
          v-if="relatedAttachments.length > this.foldedItemsLimit"
        />
      </div>

      <div v-if="cases?.length">
        <div
          v-for="(kase, i) in cases"
          :key="i"
          class="cases"
        >
          <a
            class="mt-6 caseHeader"
            @click="() => $set(casesOpen, i, !casesOpen[i])"
            v-if="kase?.decisions?.decisionHistory?.length"
          >
            <v-icon color="grey darken-1">mdi-folder-open</v-icon>
            <span class="text-body-2 font-weight-bold text--secondary ml-1">{{ $t('detail.case')}}: </span>
            <span class="text-body-2 text--secondary">{{ kase.subject }} ({{kase.entityCode}})</span>
            <v-icon color="grey darken-1">{{ casesOpen[i] ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </a>

          <div
            v-if="casesOpen[i]"
            class="text-body-2 caseContent mt-2"
          >
            <div
              v-if="kase?.decisions?.decisionHistory?.length"
            >
              <span class="text-body-2 font-weight-medium text--secondary ml-1">Status:</span>

              <ul class="text-body-2 plain-li text--secondary">
                <li
                  v-for="(item, index) in kase?.decisions.decisionHistory"
                  :key="index"
                  class="mb-1"
                >
                  <strong>{{ item.resultDate }} - </strong>
                  <span
                    :style="voteResultStatusStyling(item.overallResult)"
                  >
                    {{ item.overallResult }}
                  </span>
                </li>
              </ul>
            </div>
            <vote-results-detailed
              v-if="kase?.decisions.fullResult && kase?.decisions?.detailedResults?.length"
              :voteResults="kase?.decisions"
              class="ml-1 mt-6"
            />
          </div>
        </div>
      </div>

      <debate-details
          v-if="persons?.length || locationName"
          :locationName=locationName
          :persons=persons
          :time=debateTime
      />

    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import moment from 'moment';

import { voteResultStatusStyling } from '@/helpers/voteResultsHelper';
import VoteResultsDetailed from './VoteResultsDetailed.vue';
import ShowMoreButton from './ShowMoreButton.vue';
import DebateDetails from './DebateDetails.vue';

export default {
  name: 'BasicDetails',

  props: {
    meetings: {
      type: Array,
      required: false,
    },
    cases: {
      type: Array,
      required: false,
    },
    relatedAttachments: {
      type: Array,
      required: false,
    },
    sourceDocuments: {
      type: Array,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    documentNumbers: {
      type: Object,
      required: false,
    },
    persons: {
      type: Array,
      required: false,
    },
    locationName: {
      type: String,
      required: false,
    },
    debateTime: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      casesOpen: [],
      foldedItemsLimit: 4,
      showAllItems: {
        currentMeetings: false,
        pastMeetings: false,
        sourceDocuments: false,
        relatedAttachments: false,
      },
    };
  },

  mounted() {
    this.casesOpen = Array(this.cases?.length || 0).fill(true);
  },

  components: {
    VoteResultsDetailed,
    ShowMoreButton,
    DebateDetails,
  },

  computed: {
    currentMeetings() {
      const meetings = [];
      this.meetings.forEach((meeting) => {
        // We are only interested in dates not time
        const convertedNlDate = moment(meeting.date, 'DD-MM-YYYY').startOf('day');
        const today = moment().startOf('day');
        if (convertedNlDate.isSameOrAfter(today)) {
          meetings.push(meeting);
        }
      });
      return meetings;
    },

    pastMeetings() {
      const meetings = [];
      this.meetings.forEach((meeting) => {
        // We are only interested in dates not time
        const convertedNlDate = moment(meeting.date, 'DD-MM-YYYY').startOf('day');
        const today = moment().startOf('day');
        if (convertedNlDate.isBefore(today)) {
          meetings.push(meeting);
        }
      });
      return meetings;
    },
  },

  methods: {
    voteResultStatusStyling,

    displayedList(type) {
      if (this.showAllItems[type]) {
        return this[type];
      }
      return this[type].slice(0, this.foldedItemsLimit);
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}

.past-list{
  list-style-type: none;
  .check:before {
    content: '✓';
    margin-left: -12px;
    margin-right: 3px;
  }
}

.cases {
  .caseHeader {
    display: block;
  }
  .caseContent {
    border-left: lightgray 2px solid;
    padding-left: 1em;
    margin-left: 0.5em;
  }
}
</style>
