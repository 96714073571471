export const sleep = (ms) => new Promise((resolve) => { setTimeout(resolve, ms); });

export const stripHtml = (html, replacement) => html.replace(/<[^>]+>/g, replacement ?? '');

export const selectLogo = (locale, isThemeDark) => {
  if (locale === 'en' && !isThemeDark) {
    return 'light-english';
  }
  if (isThemeDark) {
    return 'dark';
  }
  return 'light';
};
