<template>
  <feature-teaser-dialog title="Geen toegang">
    <p>{{ $t('modules.missingModuleWarning') }}</p>
  </feature-teaser-dialog>
</template>

<script>
import FeatureTeaserDialog from '@/components/FeatureTeaserDialog.vue';

export default {
  components: {
    FeatureTeaserDialog,
  },
};
</script>
